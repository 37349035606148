import axios from 'axios'

function useSession(baseURL) {
  const urlInp = 'https://session.' + baseURL + '/api/v1/input'
  const headers = { headers: { Authorization: `Bearer public` } }

  async function getViewerInput(code) {
    try {
      const response = await axios.get(urlInp + '/viewer/' + code, headers)
      return response.data
    } catch (error) {
      return error.response.data
    }
  }

  return {
    getViewerInput,
  }
}

export default useSession
